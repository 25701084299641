/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


ion-backdrop {
  background-color: rgba(0,0,0,0);
}

.ecoya-toast-css {
  text-align: center;
  color: #ffffff;
}

.ecoya-toast-success-css {
  text-align: center;
  color: #ffffff;
  background-color: rgba(0,0,0,0);
  --background: #050505;
}


.ecoya-confirm-modal-css .modal-wrapper {
  height: 100%;
  width: 100%;
  max-width: 640px;
  position: absolute;
  display: block;
  background-color: rgba(0,0,0,0);
}

.ecoya-none-layout {
  height: 100%;
}


.ecoya-date-picker {
  position: absolute;
  z-index: 10;
  width:100%;
  height:100%;
  color: rgba(0,0,0,0);
  background-color: rgba(0,0,0,0);
}


.picker-columns,
.picker-toolbar,
.picker-md .picker-wrapper,
.picker-md .picker-toolbar
{
  background-color: #fff !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  max-width: 640px !important;
  border: none;
  color: #000000;
}
.picker-md .picker-prefix,
.picker-md .picker-suffix,
.picker-md .picker-opt.picker-opt-selected {
  color: #000000;
  font-family: Noto Sans KR;
}
.picker-md .picker-opt {
  color: #E0E0E0;
  font-family: Noto Sans KR;
}

.picker-md .picker-button,
.picker-md .picker-button.activated {
  color: #000000;
  font-family: Noto Sans KR;
}
